import { createRouter, createWebHistory } from "vue-router";
const routes = [
    {
        path : "/",
        component: () => import("@/views/Home"),
        meta: {
            title: 'Elektronik ve mobil imzalama çözümleri | Hızlı, güvenli ve bütçe dostu | İzometri Bilişim',
            metaTags: [
                { name: 'description', content: 'İzometri Bilişim: Aynı zamanda lokasyondan bağımsız özgür bir iş modeli sunarken, doğa dostu yazılım çözümleriyle belgelerinize yasal geçerlilik sağlar.' },
                { name: 'keywords', content: 'Elektronik imza, Mobil imza, Dijital imza, imza çözümleri, imza platformu, imzalama platformu, Doküman imzalama, Belge imzalama, Güvenli imza, Online imza, Hızlı imza, Güvenli elektronik imza, Kaliteli elektronik imza, Kolay kullanımlı elektronik imza, Hızlı elektronik imza, Güvenli elektronik imza, Yerli elektronik imza' }
            ]
        }
    },
    {
        path : "/urunler/izonay",
        name: "izonay",
        component: () => import("@/views/Izonay")
    },
    {
        path : "/urunler/izimza",
        name: "izimza",
        component: () => import("@/views/Izimza")
    },
    {
        path : "/urunler/mip",
        name: "mip",
        component: () => import("@/views/Mip"),
        meta: {
            title: 'Merkezi İmzalama Platformu | Yenilikçi ve Uyumlu! | İzometri Bilişim',
            metaTags: [
                { name: 'description', content: 'Uyumlu yazılımı sayesinde birden fazla uygulamayı tek bir ara yüzde buluşturarak belgelerinizi imzalayabilir, zamandan tasarruf sağlayabilirsiniz' },
                { name: 'keywords', content: 'Belge imzalama, Güvenli imza, Yenilikçi imza, Uyumlu imza, Kolay kullanımlı elektronik imza, Hızlı elektronik imza, Merkezi İmzalama Platformu' }
            ]
        }
    },
    {
        path : "/iletisim",
        component: () => import("@/views/Contact")
    },
    {
        path : "/sifreleme",
        component: () => import("@/views/Sifreleme")
    },
    {
        path : "/urunler/imzalayiciApp",
        name: "imzalayici",
        component: () => import("@/views/Imzalayici")
    },
    {
        path : "/bluetooth-imza",
        component: () => import("@/views/BluetoothImza")
    },
    {
        path : "/e-imza",
        component: () => import("@/views/EImza")
    },
    {
        path : "/e-muhur",
        component: () => import("@/views/EMuhur")
    },
    {
        path : "/kvkk",
        component: () => import("@/views/FooterLinks/Kvkk")
    },
    {
        path : "/bilgi-guvenligi",
        component: () => import("@/views/FooterLinks/BilgiGuvenligiPolitikasi")
    },
    {
        path : "/cerez-politikasi",
        component: () => import("@/views/FooterLinks/CookiePolicy")
    },
    {
        path : "/etk",
        component: () => import("@/views/ETK")
    },
    {
        path: "/:notFound",
        component: () => import("@/views/NotFound.vue"),
        meta: {
          title: 'Sayfa Bulunamadı | İzometri Bilişim',
        }
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: function (to, _from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        }
        if (to.hash) {
          return { el: to.hash, behavior: "smooth" };
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }
     }
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    }
  
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el));
  
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    if (!nearestWithMeta) return next();
  
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
  
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
  
      tag.setAttribute('data-vue-router-controlled', '');
  
      return tag;
    }).forEach(tag => document.head.appendChild(tag));
  
    next();
  });

router.beforeEach((to, from, next) => {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    const href = `${window.location.origin}${to.path}`;
    if (canonicalLink) {
      canonicalLink.setAttribute('href', href);
    } else {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', href);
      document.head.appendChild(link);
    }
    next();
  });

export default router;