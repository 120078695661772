import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager } from 'vue-meta';

import { OhVueIcon, addIcons } from "oh-vue-icons";
import { FaKeycdn,FaSignature ,FaProjectDiagram, FaMobile, FaFileSignature, FaHandshake,FaFileInvoice,FaPeopleCarry,FaAddressCard,FaGlobe,FaBalanceScale,FaSolarPanel,FaConnectdevelop,FaCloud,FaFileAlt,FaEnvelope,FaHandsWash,FaUserShield } from "oh-vue-icons/icons/fa";

addIcons(FaKeycdn, FaSignature,FaProjectDiagram,FaMobile, FaFileSignature, FaHandshake,FaFileInvoice,FaPeopleCarry,FaAddressCard,FaGlobe,FaBalanceScale,FaSolarPanel,FaConnectdevelop,FaCloud,FaFileAlt,FaEnvelope,FaHandsWash,FaUserShield );

const app = createApp(App);
const metaManager = createMetaManager();
app.use(router)
app.use(metaManager);

app.component("v-icon", OhVueIcon);
app.mount('#app');
