<template>
  <router-view></router-view>
</template>

<script>
import { RouterView } from 'vue-router';
export default {
  name: 'App',
  components: {
    RouterView
}
}
</script>

<style>
*{
  box-sizing: border-box;
  margin: 0;
}
#app{
  font-family: 'Montserrat';
}
</style>
